import React from "react"
import { Section, Columns } from "../Containers"
import { Text, Image } from "../Core"
import { Button } from "../Button"

import "./_LifestyleFeature.scss"

const LifestyleFeature = ({
  hasThisSection,
  content,
  procedures,
  colorBack = true
}) => {
  if (!hasThisSection) return null

  const hasTwo = procedures.length === 2

  return (
    <Section colorBack={colorBack}>
      <Columns
        sideColumnsSize={2}
        sideColumnClass="is-hidden-mobile"
        className="lifestyle-feature is-vcentered">
        {hasTwo && (
          <>
            <div className="column is-5 ls-feature__order-2">
              <Card image={procedures[0].image} button={procedures[0].button} />
            </div>
            <div className="column is-1 is-hidden-mobile"></div>
          </>
        )}

        <div
          className={`column ls-feature__order-1 ${!hasTwo ? " is-half" : ""}`}>
          <Text className={hasTwo ? "has-text-centered" : ""} text={content} />
          {!hasTwo && (
            <Button {...procedures[0].button} className="mx-auto--mobile" />
          )}
        </div>

        {!hasTwo && (
          <>
            <div className="column is-1 is-hidden-mobile"></div>
            <div className="column ls-feature__order-2">
              <Card image={procedures[0].image} />
            </div>
          </>
        )}

        {hasTwo && (
          <>
            <div className="column is-1 is-hidden-mobile"></div>
            <div className="column is-5 ls-feature__order-3">
              <Card image={procedures[1].image} button={procedures[1].button} />
            </div>
          </>
        )}
      </Columns>
    </Section>
  )
}

const Card = ({ image, button }) => {
  return (
    <>
      <Image publicId={image} />
      {button && <Button className="mx-auto" {...button} />}
    </>
  )
}

export default LifestyleFeature
